import { Suspense, lazy, StrictMode } from "react";
import ReactDOM from "react-dom";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import reportWebVitals from "./reportWebVitals";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, LinearProgress, ThemeProvider } from "@mui/material";
import { createUploadLink } from "apollo-upload-client";
import { hostnameApi, protocol } from "./common/contants";

const Root = lazy(() =>
  import("./containers/Root/Root").then((module) => ({ default: module.Root }))
);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: createUploadLink({
    uri: `${protocol}//${hostnameApi}/graphql`,
  }),
});

const theme = createTheme({
  typography: {
    body1: {
      lineHeight: "normal",
    },
  },
  palette: {
    primary: {
      main: "#BB86FC",
    },
    secondary: {
      main: "#03DAC5",
    },
    mode: "dark",
  },
});

ReactDOM.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <CssBaseline />
        <Suspense fallback={<LinearProgress />}>
          <Root />
        </Suspense>
      </ApolloProvider>
    </ThemeProvider>
  </StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
function sendToAnalytics({ id, name, value }: any) {
  window.gtag('send', 'event', {
    event_category: 'Web Vitals',
    event_action: name,
    event_value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
    event_label: id, // id unique to current page load
    non_interaction: true, // avoids affecting bounce rate
  });
}

reportWebVitals(sendToAnalytics);